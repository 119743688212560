import request from '@/utils/request'
import qs from 'qs'

export function checkLoanPermission(params) {
  return request({
    url: 'api/loan/checkLoanPermission',
    method: 'post',
    params
  })
}

export function add(data) {
  return request({
    url: 'api/loan',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/loan',
    method: 'delete',
     data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/loan',
    method: 'put',
    data
  })
}

export function audit(data) {
  return request({
    url: 'api/loan/audit',
    method: 'post',
    data
  })
}
export function countByStuNo(stuNo) {
  return request({
    url: 'api/loan/' + stuNo,
    method: 'get'
  })
}



export default { del, edit, add, audit, checkLoanPermission, countByStuNo }